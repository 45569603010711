@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import "global-override.less";

html {
  /* rem em */
  /*em 相对于父元素的font-size*/
  /*rem 相对于根元素html的font-size, r就是root的意思*/
  /*16 * 62.5% = 10px*/
  /*1rem === 10px*/
  font-size: 62.5%;
}

/*viewport height === vh*/
html body #root .App {
  min-height: 100vh;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

@primary-color: rgb(0, 82, 204);@font-size-base: 14px;